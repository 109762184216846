import React from "react";
import { getRouteFromString } from "helpers/importExport/route/get-route-from-string";
import { SetContextErrorType } from "../../../../contexts/ErrorContext";
import WayfinderSupportEmailLink from "../../../../DLS/WayfinderEmailLink";
import {
  logRouteValidationErrors,
  RouteValidationResult,
  validateAndFixRoute,
  validateRoute,
} from "../../../../helpers/importExport/route/validation";
import { Route } from "../../../../shared-types/RouteTypes";

export const handleFilesImport = async (
  file: File,
  enableFurunoSimple: boolean,
  enableJsonRoutes: boolean,
  handleLoadingChange: (loading: boolean) => void,
  handleSetError: SetContextErrorType,
  onSuccess: (
    route: Route,
    validationResult: RouteValidationResult,
    fileName?: string
  ) => void,
  voyageUuid?: string
) => {
  const importedFileContent = await file.text();
  let route: Route | undefined = undefined;
  try {
    ({ route } = getRouteFromString(importedFileContent, {
      enableFurunoSimple,
      enableJsonRoutes,
    }));
  } catch (error) {
    handleLoadingChange(false);
    handleSetError({
      error: error as Error,
      description: (
        <>
          There was an issue importing your route. Please reach out to us at{" "}
          <WayfinderSupportEmailLink /> for support.
        </>
      ),
    });
    console.error(error);
  }
  if (route) {
    handleLoadingChange(true);
    if (route.routeInfo?.routeName === "") {
      route.routeInfo.routeName = file.name;
    }
    const validationResult = validateRoute({ route, isRouteImported: true });
    if (!validationResult.isValid && validationResult.errors?.length) {
      console.warn(
        "Imported route fails validation. Will try to fix.",
        validationResult.errors
      );
      // Let sentry know if the route is not valid as imported
      logRouteValidationErrors(route, validationResult.errors, {
        importedFileContent,
        voyageUuid,
      });
    }

    // Then try fixing the route, and deal with any remaining issues
    // If the route is missing either speeds or times, then validateAndFixRoute() will compute the missing values
    const fixResult = validateAndFixRoute({ route, isRouteImported: true });
    const otherErrors = fixResult.errors?.filter(
      (e) => e.type !== "missing-speeds" && e.type !== "missing-times"
    );
    if (fixResult.isValid || !otherErrors?.length) {
      handleLoadingChange(false);
      onSuccess(route, fixResult, file.name);
    } else {
      // We can only fix missing speeds and times, so if there are other errors, bail
      console.warn(
        "Imported route could not be fixed. Aborting import",
        fixResult.errors
      );
      // Log error and route to sentry
      if (fixResult.errors) {
        logRouteValidationErrors(route, fixResult.errors, {
          importedFileContent,
          voyageUuid,
          isFixed: true,
        });
      }
      handleLoadingChange(false);
      handleSetError({
        error: Error(
          `Could not fix invalid imported route. Aborting import. Details: \n${otherErrors
            .map((e) => e.message)
            .join("\n")}`
        ),
        description: "There was an error while importing the route.",
        showDetails: true,
      });
    }
  }
};
