import {
  FleetSummaryConfigurationDto,
  FleetSummaryDto,
  GeoJsonPointDto,
  OrganizationDto,
  RtzJsonDto,
} from "@sofarocean/wayfinder-typescript-client";
import AnalyticsContext, { AnalyticsEvent } from "contexts/Analytics";
import useAppSetting from "contexts/AppSettingsContext";
import { RouteStoreDispatchContext } from "contexts/RouteStoreContext";
import useRoute from "contexts/RouteStoreContext/use-route";
import { useMapInspectorState } from "shared-hooks/MapInspector/use-map-inspector-state";
import { convertRtzJsonDtoToRoute } from "helpers/api";
import { normalizeLongitude } from "helpers/geometry";
import { validateAndFixRoute } from "helpers/importExport/route/validation";
import { BOUNDS_PADDING_BUFFER, BOUNDS_PADDING_DEFAULT } from "helpers/routes";
import { GLOBAL_MAP_BOUNDS } from "helpers/weather";
import { clone, isNil, isNumber, keyBy, orderBy } from "lodash";
import React, {
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import { useBottomPanelHeight } from "screens/VoyageScreen/panel-dimensions";
import { useFleetSummary } from "shared-hooks/data-fetch-hooks/use-fleet-summary";
import {
  UpcomingPathDtoWithRtz,
  useUpcomingPaths,
} from "shared-hooks/data-fetch-hooks/use-upcoming-paths";
import { useOrganizations } from "shared-hooks/data-fetch-hooks/useOrganizations";
import {
  isVesselSelected,
  SelectedVesselsWithGroups,
  useStoredVesselSelections,
} from "shared-hooks/use-stored-selected-vessels";
import { useWayfinderUrl } from "shared-hooks/use-wayfinder-url";
import { MapBounds } from "shared-types";
import { match } from "ts-pattern";

export const FLEET_VIEW_TABS = ["table", "list", "cp-analysis", "cii"] as const;
export type FleetViewTab = typeof FLEET_VIEW_TABS[number];

export type VesselListMode = "all" | "favorite-vessels" | "selected-vessels";

export type FleetViewContextType = {
  fleet: FleetSummaryDto[];
  filteredFleet: FleetSummaryDto[];
  configuration?: FleetSummaryConfigurationDto;
  highlightedVessel?: { uuid: string } | undefined;
  // pass an object to setHighlightedVessel so we can refresh state even when the uuid is not different
  // this allows us to unhide the inspector when a user clicks the map icon again
  setHighlightedVessel: (vessel: { uuid: string } | undefined) => void;
  fleetSummaryByUuid: Record<string, FleetSummaryDto>;
  filteredFleetSummaryByUuid: Record<string, FleetSummaryDto>;
  vesselPositionsAreLoading: boolean;
  vesselsAreLoading: boolean;
  fleetViewMapBounds: MapBounds;
  setFleetSummary: React.Dispatch<
    React.SetStateAction<{
      summary: FleetSummaryDto[];
      configuration: FleetSummaryConfigurationDto | undefined;
      summaryIsLoading: boolean;
    }>
  >;
  fleetViewTab: FleetViewTab;
  setFleetViewTab: (tab: FleetViewTab) => void;
  organizationOverride?: OrganizationDto;
  setOrganizationOverride: (organization: OrganizationDto | undefined) => void;
  selectedVessels: SelectedVesselsWithGroups;
  setSelectedVessels: (selection: SelectedVesselsWithGroups) => void;
  favoriteVessels: SelectedVesselsWithGroups;
  vesselListMode: VesselListMode;
  setVesselListMode: (value: VesselListMode) => void;
  resetMap: () => void;
  summaryQuery: Omit<
    ReturnType<typeof useFleetSummary>,
    "data" | "configuration"
  >;
  highlightedVesselRouteUuid: string | undefined;
  upcomingPaths: UpcomingPathDtoWithRtz[] | undefined;
};

const FleetViewContextDefaults: FleetViewContextType = {
  fleet: [],
  filteredFleet: [],
  configuration: undefined,
  highlightedVessel: undefined,
  setHighlightedVessel: () => null,
  fleetSummaryByUuid: {},
  filteredFleetSummaryByUuid: {},
  vesselPositionsAreLoading: false,
  vesselsAreLoading: false,
  fleetViewMapBounds: { bounds: [0, 0, 0, 0] },
  setFleetSummary: (value) => undefined,
  fleetViewTab: "table",
  setFleetViewTab: (value) => undefined,
  organizationOverride: undefined,
  setOrganizationOverride: () => undefined,
  selectedVessels: {
    vesselGroupSelections: [],
    selectedUngroupedVessels: [],
  },
  setSelectedVessels: (selection) => undefined,
  favoriteVessels: {
    vesselGroupSelections: [],
    selectedUngroupedVessels: [],
  },
  vesselListMode: "all",
  setVesselListMode: () => undefined,
  resetMap: () => null,
  summaryQuery: {
    isLoading: undefined,
    isFetching: false,
    isRefetching: false,
    fetchingProgress: null,
    refetch: undefined,
    dataTimestamp: null,
  },
  highlightedVesselRouteUuid: undefined,
  upcomingPaths: undefined,
};

export const FleetViewContext = React.createContext<FleetViewContextType>(
  FleetViewContextDefaults
);

export const useFleetViewState = (): FleetViewContextType => {
  const { trackAnalyticsEvent } = useContext(AnalyticsContext);
  const [highlightedVessel, setHighlightedVessel] = useState<
    | {
        uuid: string;
      }
    | undefined
  >(undefined);

  const [organizationOverride, setOrganizationOverride] = useState<
    OrganizationDto | undefined
  >(undefined);

  const { selectedVessels, setSelectedVessels } = useStoredVesselSelections(
    "fleetViewSelectedVessels"
  );
  const { selectedVessels: favoriteVessels } = useStoredVesselSelections(
    "favoriteVessels"
  );

  const {
    value: vesselListModeSetting,
    setValue: setVesselListModeBase,
  } = useAppSetting("fleetViewVesselListMode");
  const setVesselListMode = useCallback(
    (value: string) => {
      trackAnalyticsEvent(AnalyticsEvent.FleetViewVesselListModeChanged, {
        oldVesselListMode: vesselListModeSetting,
        newVesselListMode: value,
      });
      setVesselListModeBase(value);
    },
    [setVesselListModeBase, trackAnalyticsEvent, vesselListModeSetting]
  );
  const vesselListMode = (vesselListModeSetting as VesselListMode) ?? "all";

  // for the display in the office, we want to set the org using an app setting sometimes
  const { value: fleetOrgFilterString } = useAppSetting("fleetOrgFilterString");
  const { orgs } = useOrganizations();
  useEffect(() => {
    if (orgs?.length && fleetOrgFilterString) {
      const org = orgs.find(
        (o) =>
          o.displayName
            ?.toLocaleLowerCase()
            ?.indexOf(fleetOrgFilterString.toLocaleLowerCase()) === 0
      );
      if (org) {
        setOrganizationOverride(org);
      }
    }
  }, [fleetOrgFilterString, orgs]);

  const {
    clearMapInspector,
    activeMapInspector,
    setActiveMapInspector,
    mapInspectorsEnabled,
  } = useMapInspectorState();

  const globalBounds = GLOBAL_MAP_BOUNDS;

  const [fleetSummary, setFleetSummary] = useState<{
    summary: FleetSummaryDto[];
    configuration: FleetSummaryConfigurationDto | undefined;
    summaryIsLoading: boolean;
  }>({
    summary: [] as FleetSummaryDto[],
    configuration: undefined,
    summaryIsLoading: true,
  });

  const {
    fleetViewTab: fleetViewTabQuery,
    setFleetViewTab: setFleetViewTabUrl,
  } = useWayfinderUrl();

  const [fleetViewTab, setFleetViewTabState] = useState<FleetViewTab>(
    fleetViewTabQuery ?? "table"
  );

  const setFleetViewTab = useCallback(
    (tab: FleetViewTab) => {
      setFleetViewTabState(tab);
      setFleetViewTabUrl(tab);
    },
    [setFleetViewTabState, setFleetViewTabUrl]
  );

  const fleet = useMemo(
    () => orderBy(fleetSummary.summary, (vessel) => vessel.summary?.vesselName),
    [fleetSummary.summary]
  );

  const filteredFleet = useMemo(
    () =>
      fleet.filter((vessel) => {
        const inOrganizationOverride =
          !organizationOverride ||
          vessel.summary.vesselOrganizationId === organizationOverride.id;
        const matchesVesselMode = isVesselSelected(
          {
            uuid: vessel.summary.vesselUuid,
            name: vessel.summary.vesselName,
            groupUuid: vessel.summary.vesselGroupUuid,
            toRecipients: [],
            ccRecipients: [],
          },
          match(vesselListMode)
            .with("all", () => "all" as const)
            .with("selected-vessels", () => selectedVessels)
            .with("favorite-vessels", () => favoriteVessels)
            .exhaustive()
        );

        return matchesVesselMode && inOrganizationOverride;
      }),
    [
      favoriteVessels,
      fleet,
      organizationOverride,
      selectedVessels,
      vesselListMode,
    ]
  );

  const fleetSummaryByUuid = useMemo(() => {
    const out: Record<string, FleetSummaryDto> = {};

    for (const row of fleetSummary.summary) {
      if (row.summary) {
        out[row.summary.vesselUuid] = row;
      }
    }

    return out;
  }, [fleetSummary]);

  const filteredFleetSummaryByUuid = useMemo(() => {
    const out: Record<string, FleetSummaryDto> = {};

    for (const row of filteredFleet) {
      if (row.summary) {
        out[row.summary.vesselUuid] = row;
      }
    }

    return out;
  }, [filteredFleet]);

  const resetMap = useCallback(() => {
    clearMapInspector();
    setHighlightedVessel(undefined);
    setOrganizationOverride(undefined);
  }, [clearMapInspector, setHighlightedVessel, setOrganizationOverride]);

  useEffect(() => {
    if (highlightedVessel?.uuid === undefined) {
      // only clear map inspector if it is a fleet view vessel
      if (activeMapInspector?.type === "fleetViewVessel") clearMapInspector();
    } else if (mapInspectorsEnabled) {
      const record = fleetSummaryByUuid[highlightedVessel?.uuid];

      const vesselUuid = record.summary.vesselUuid;
      const latestPosition =
        record.summary.lastKnownPosition?.point.coordinates;

      const lat = latestPosition && latestPosition[1];
      const lon = latestPosition && latestPosition[0];

      if (!isNil(lat) && !isNil(lon)) {
        setActiveMapInspector({
          type: "fleetViewVessel",
          vesselUuid,
          position: {
            lat,
            lon,
          },
        });
      }
    }
    // We only want to trigger this effect when highlightedVessel changes
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [highlightedVessel]);

  useEffect(() => {
    if (
      activeMapInspector &&
      activeMapInspector.type !== "fleetViewVessel" &&
      activeMapInspector.type !== "safety"
    ) {
      setHighlightedVessel(undefined);
    }
  }, [activeMapInspector]);

  const upcomingPaths = useUpcomingPaths();

  const upcomingPathsByVesselUuid = useMemo(
    () => keyBy(upcomingPaths, "vesselUuid"),
    [upcomingPaths]
  );

  const highlightedRoute = highlightedVessel?.uuid
    ? (upcomingPathsByVesselUuid[highlightedVessel.uuid]?.path as
        | RtzJsonDto
        | undefined)
    : undefined;

  // add highlighted route to the route store locally so the plot can see it
  const { createRoute } = useContext(RouteStoreDispatchContext);
  const storedRoutes = useRef<string[]>([]);
  useEffect(() => {
    const routeUuid = highlightedRoute?.extensions.uuid;
    if (routeUuid && !storedRoutes.current.includes(routeUuid)) {
      const route = convertRtzJsonDtoToRoute({ rtzJsonDto: highlightedRoute })
        .rtzRoute;
      const validationResult = validateAndFixRoute({
        route,
        isRouteImported: false,
      });
      if (!validationResult.isValid) {
        console.error(validationResult);
      }
      createRoute(
        routeUuid,
        route,
        undefined, // no voyage for this route
        { doNotPersist: true }
      );
      storedRoutes.current.push(routeUuid);
    }
  }, [createRoute, highlightedRoute]);
  const highlightedRouteFromStore = useRoute(
    highlightedRoute?.extensions.uuid,
    true
  );
  const highlightedVesselRouteUuid =
    // only provide the uuid once its route is in the store
    highlightedRouteFromStore.route?.extensions?.uuid;

  const bottomPanelHeight = useBottomPanelHeight();

  const fleetViewMapBounds = useMemo(() => {
    let bounds: MapBounds;
    const vesselMapBounds = getVesselsPositionMapBounds(
      Object.values(fleetSummaryByUuid).map(
        (v) => v?.summary.lastKnownPosition?.point
      )
    );
    if (vesselMapBounds) {
      vesselMapBounds.options = {
        padding: {
          top: BOUNDS_PADDING_DEFAULT,
          right: BOUNDS_PADDING_DEFAULT,
          bottom: bottomPanelHeight + BOUNDS_PADDING_BUFFER,
          left: BOUNDS_PADDING_DEFAULT,
        },
      };
      bounds = vesselMapBounds;
    } else {
      // we will edit it when centering, so clone first
      bounds = clone(globalBounds);
    }
    return bounds;
  }, [bottomPanelHeight, fleetSummaryByUuid, globalBounds]);

  const summaryQuery = useFleetSummary(fleet.length === 0);

  useEffect(() => {
    if (!summaryQuery.isLoading && summaryQuery.data.length !== 0) {
      setFleetSummary({
        summary: summaryQuery.data,
        configuration: summaryQuery.configuration,
        summaryIsLoading: false,
      });
    }
  }, [
    setFleetSummary,
    summaryQuery.isLoading,
    summaryQuery.data,
    summaryQuery.configuration,
  ]);

  return useMemo(
    () => ({
      fleet,
      filteredFleet,
      configuration: fleetSummary.configuration,
      vesselsAreLoading: fleetSummary.summaryIsLoading ?? false,
      fleetSummaryByUuid,
      filteredFleetSummaryByUuid,
      vesselPositionsAreLoading: fleetSummary.summaryIsLoading ?? false,
      highlightedVessel,
      setHighlightedVessel,
      fleetViewMapBounds,
      setFleetSummary,
      fleetViewTab,
      setFleetViewTab,
      organizationOverride,
      setOrganizationOverride,
      selectedVessels,
      setSelectedVessels,
      favoriteVessels,
      vesselListMode,
      setVesselListMode,
      resetMap,
      summaryQuery,
      highlightedVesselRouteUuid,
      upcomingPaths,
    }),
    [
      fleet,
      filteredFleet,
      fleetSummary.configuration,
      fleetSummary.summaryIsLoading,
      fleetSummaryByUuid,
      filteredFleetSummaryByUuid,
      highlightedVessel,
      fleetViewMapBounds,
      fleetViewTab,
      setFleetViewTab,
      organizationOverride,
      resetMap,
      summaryQuery,
      highlightedVesselRouteUuid,
      upcomingPaths,
      setOrganizationOverride,
      selectedVessels,
      setSelectedVessels,
      favoriteVessels,
      vesselListMode,
      setVesselListMode,
    ]
  );
};

/**
 * Finds the bounds of the route. Mapbox will always choose the smaller number for the west. So westmost number should always be smaller
 * This is accomplished by allowing values > 180 and < -180 rather than normalizing the results
 * @param route
 */
export function getVesselsPositionMapBounds(
  vesselPositions: (GeoJsonPointDto | undefined)[]
): MapBounds | undefined {
  // create a list of delta from start, accumulating delta at each step so that we are unlikely to go past 180 at any step and get a discontinuity
  const relativePositions = vesselPositions.reduce<
    { lat: number; lon: number }[]
  >((relativePositions, vesselPosition, index) => {
    relativePositions.push({
      lat: vesselPosition?.coordinates[1] ?? 0,
      lon: normalizeLongitude(vesselPosition?.coordinates[0] ?? 0),
    });
    return relativePositions;
  }, []);
  const minLatLng = relativePositions.reduce(
    (result, current) => {
      result.lon = Math.min(current.lon, result.lon);
      result.lat = Math.min(current.lat, result.lat);
      return result;
    },
    { ...relativePositions[0] }
  );
  const maxLatLng = relativePositions.reduce(
    (result, current) => {
      result.lon = Math.max(current.lon, result.lon);
      result.lat = Math.max(current.lat, result.lat);
      return result;
    },
    { ...relativePositions[0] }
  );
  // add the first lon back to the relative points
  minLatLng.lon = normalizeLongitude(minLatLng.lon);
  maxLatLng.lon = normalizeLongitude(maxLatLng.lon);
  // mapbox will use the lowest as the westmost bound, so make sure the min is less than the max
  // (adding back in the first waypoint lon and normalizing could have wrapped them)
  if (maxLatLng.lon < minLatLng.lon) {
    maxLatLng.lon += 360;
  }
  if (
    minLatLng.lon === 0 &&
    minLatLng.lat === 0 &&
    maxLatLng.lon === 0 &&
    maxLatLng.lat === 0
  ) {
    return undefined;
  }
  const hasBounds =
    isNumber(minLatLng.lon) &&
    isNumber(minLatLng.lat) &&
    isNumber(maxLatLng.lon) &&
    isNumber(maxLatLng.lat);
  return hasBounds
    ? {
        bounds: [minLatLng.lon, minLatLng.lat, maxLatLng.lon, maxLatLng.lat],
      }
    : undefined;
}

export const FleetViewContextProvider: React.FC<{}> = ({ children }) => {
  const state = useFleetViewState();
  return (
    <FleetViewContext.Provider value={state}>
      {children}
    </FleetViewContext.Provider>
  );
};
